
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import { mobileRegExp, emailRegExp } from '@/utils/reg'
import httpHelper from '@/utils/request'
import configs from '@/configs'
import { getMBankAuth, setMBankAuth } from '@/utils/cookies'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'
import { setGoBackUrl } from '@/utils/gobackurl'

@Component({
  name: 'ccblogin',
  mixins: [GlobalMixin]
})
export default class PartnerLoginPage extends Vue {
  @Provide() public ischeck: boolean = false
  @Provide() public showLogin: boolean = false

  public async created () {
    console.log('>>>>>', this.showLogin)
    await this.getCCB()
    if (!this.showLogin) {
      this.doAuth()
    }
  }
  @Emit()
  public async getCCB () {
    const params = getCurrentQuery('params')
    try {
      const ccb = await httpHelper.post({
        url: 'authorize/AuthValid',
        data: { grant_type: 24, params: { param: params } }
      })
      if (ccb.code === 0) {
        this.showLogin = !ccb.value
      }
    } catch (err) {
      console.log('err', err)
      this.$toasted.show(err.msg)
    }
  }

  @Emit()
  public select () {
    this.ischeck = !this.ischeck
  }

  @Emit()
  public async next () {
    if (!this.ischeck) {
      this.$toasted.show('勾选下方同意后，才可去购物哦~')
      return false
    }
    setMBankAuth()
    this.doAuth()
  }

  @Emit()
  public async doAuth () {
    const params = getCurrentQuery('params')
    try {
      const ccb = await httpHelper.getAuthLogin({ params: params }, 'authorize/ccb')
      if (ccb.code === 0) {
        location.replace(`https://jxjk1-h5.benlai.com/authorize/ccb${location.search}`)
      }
    } catch (err) {
      console.log('err', err)
      this.$toasted.show(err.msg)
    }
  }
  @Emit()
  public goback () {
    history.back()
  }
}
