
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import { mobileRegExp, emailRegExp } from '@/utils/reg'
import httpHelper from '@/utils/request'
import configs from '@/configs'
import { getMBankAuth, setMBankAuth, setCCBNJInfo, setCCBNJcampusId } from '@/utils/cookies'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'
import { setGoBackUrl } from '@/utils/gobackurl'

@Component({
  name: 'ccblogin',
  mixins: [GlobalMixin]
})
export default class PartnerLoginPage extends Vue {
  @Provide() public ischeck: boolean = false
  @Provide() public showLogin: boolean = false
  isShow: boolean = false
  // 江苏&南京建行饭卡对接
  public async created () {
    await this.checkCCB()
    if (this.showLogin) {
      this.doAuth()
    } else {
      this.isShow = true
    }
  }
  @Emit()
  public async checkCCB () {
    const ccbSafeParamBZ = getCurrentQuery('ccbSafeParamBZ')
    try {
      const ccb = await httpHelper.post({
        url: 'authorize/AuthValid',
        data: {
          grant_type: 47,
          params: {
            param: ccbSafeParamBZ
          }
        }
      })
      if (ccb.code === 0) {
        this.showLogin = !!ccb.value
      }
    } catch (err) {
      console.log('err', err)
      this.$toasted.show(err.msg)
    }
  }

  @Emit()
  public select () {
    this.ischeck = !this.ischeck
  }

  @Emit()
  public async next () {
    if (!this.ischeck) {
      this.$toasted.show('勾选下方同意后，才可去购物哦~')
      return false
    }
    setMBankAuth()
    this.doAuth()
  }

  @Emit()
  public async doAuth () {
    const ccbSafeParamBZ = getCurrentQuery('ccbSafeParamBZ')
    const CORP_ID = getCurrentQuery('CORP_ID')
    const themeColor = getCurrentQuery('themeColor')
    const CAMPUS_ID = getCurrentQuery('CAMPUS_ID')
    const EXT1 = getCurrentQuery('EXT1')
    try {
      let data = {
        ccbSafeParamBZ,
        CORP_ID,
        themeColor,
        CAMPUS_ID,
        EXT1
      }
      setCCBNJInfo(JSON.stringify(data))
      const ccb = await httpHelper.getAuthLogin(data, 'authorize/ccbjxjkNJMealCard')
      if (ccb.code === 0) {
        let value = ccb.value || {}
        setCCBNJcampusId(value.extend)
        location.replace(`${value.extend.gotoUrl}`)
      }
    } catch (err) {
      this.goTo(err, 4, 47)
      this.$toasted.show(err.msg)
    }
  }
  @Emit()
  public goback () {
    history.back()
  }
}
